/////// clickable event for feaure-card //////

class ClickableCardDeck {
    private $compSelector: HTMLElement
    private $cards: NodeListOf<Element>

    constructor($theComponentSelector: HTMLElement) {
        this.$compSelector = $theComponentSelector;
        this.$cards = this.$compSelector.querySelectorAll('.c-card-deck__item .featurecard');
        this.init()
    }

    init() {
        let _self = this
        _self.initClickableCardDeck()
    }


    initClickableCardDeck(): void {

        this.$cards.forEach(function (item: HTMLElement) {

            let anchor = item.querySelector(".c-feature-card__link");
            if (anchor != undefined) {
                let href = anchor.getAttribute("href");
                let target = anchor.getAttribute("target");
                if (target == undefined) {

                    target = "_self";
                }

                if (href !== undefined || href != ""){
                item.addEventListener("click", function () { window.open(href, target) });
                item.style.cursor = "pointer";
                 }
            }
        });
    }
}
let clickableCardDeckObj: any

function clickableCardDeckInstance() {
    let clickableCardDecks = (document.querySelectorAll('.c-card-deck--5-across') as any as Array<HTMLElement>)

    Array(...clickableCardDecks).forEach(function (item: HTMLElement) {

        clickableCardDeckObj = new ClickableCardDeck(item)
    })

}

clickableCardDeckInstance()