class ToggleCardDeck {
    private $compSelector: HTMLElement
    private $cards: NodeListOf<Element>

    constructor($theComponentSelector: HTMLElement) {
        this.$compSelector = $theComponentSelector;
        this.$cards = this.$compSelector.querySelectorAll('.c-card-deck__item');
        this.init()
    }

    init() {
        let _self = this
        _self.initToggleCards()
    }

    /* 
    *   In order for this to work, the content editor needs to provide two separate image files located in the same directory, 
    *   using very specific naming:
    *     - if the default file that is displayed is named something like "globe.svg", then the "hover" version would 
    *       be named with "hover" in filename: "globe-hover.svg"
    * 
    *     - it's recommended that the content editor organize the images within color/theme directories. So, for example they might create a blue 
    *       folder and a pink folder here:
    * 
    *           ~/content/dam/marsh-mclennan-agency-us/theme/images/blue
    *           ~/content/dam/marsh-mclennan-agency-us/theme/images/pink
    * 
    *       and then place the theme-specific default image and associated "hover" image in each:
    * 
    *           ~/content/dam/marsh-mclennan-agency-us/theme/images/blue/globe.svg
    *           ~/content/dam/marsh-mclennan-agency-us/theme/images/blue/globe-hover.svg
    * 
    *           ~/content/dam/marsh-mclennan-agency-us/theme/images/pink/globe.svg
    *           ~/content/dam/marsh-mclennan-agency-us/theme/images/pink/globe-hover.svg
    *    
    *   NOTE: The content editor must include a "hover" version of the image, otherwise it will display as a broken image when hovered over
    */

    initToggleCards(): void { 
        this.$cards.forEach(function (item: HTMLElement) {
            let src = item.querySelector("img").src;
            if(src) {
                let defaultSrc = src;
                item.addEventListener('mouseenter', event => {
                    var index = src.lastIndexOf('.');
                    const pair = Array.from(src);
                    pair.splice(index, 0, '-hover');
                    item.querySelector("img").src = pair.join('');
                });
            
                item.addEventListener('mouseleave', event => {
                    item.querySelector("img").src = defaultSrc;
                });
            }
        });
    }
}

let toggleCardDeckObj: any

function toggleCardDeckInstance() {
    let toggleCardDecks = (document.querySelectorAll('.toggle-card-deck') as any as Array<HTMLElement>)

    Array(...toggleCardDecks).forEach(function (item: HTMLElement) {
        toggleCardDeckObj = new ToggleCardDeck(item)
    })

}

toggleCardDeckInstance()